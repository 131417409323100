<template>
  <div class="form-group">
    <label v-if="name">{{ name }}</label>
    <b-form-input
      class="form-control form-control-lg form-control-solid"
      :type="type"
      v-model="input"
      :readonly="isReadOnly"
    />
    <!-- This is a form text block (formerly known as help block) -->
    <b-form-text v-if="helpText">{{ helpText }}</b-form-text>
  </div>
</template>
<script>
export default {
  name: "NotValidatedInput",
  props: {
    type: {
      String,
      default: "text"
    },
    name: String,
    value: [String, Number],
    helpText: String,
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
