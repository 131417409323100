<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um Firmendetails
        zu erfassen. Das Erfassen von Firmentetails ist im Grundpreis des
        Jahresabonement enthalten.
      </template>
      <!--      <hr class="my-4" />-->
      <!--      <p>-->
      <!--        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment-->
      <!--        hinzufügen.-->
      <!--      </p>-->
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen</b-button
        >
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->

    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                Kennzahlen
              </h4>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <label>Rechtsform</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyDetails.legal_form"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Option auswählen --</b-form-select-option
                        >
                      </template>
                      <option
                        v-for="(legal_form, key) in companyDetails.legal_forms"
                        :key="key"
                        :value="legal_form[0]"
                      >
                        {{ legal_form[1] }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <label>Gründungsjahr</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyDetails.founding_year"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Jahr auswählen --</b-form-select-option
                        >
                      </template>
                      <option v-for="year in years" :key="year" :value="year">
                        {{ year }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <label>Gegründet als</label>
                    <b-form-select
                      class="form-control form-control-lg form-control-solid"
                      size="lg"
                      v-model="companyDetails.founded_as"
                    >
                      <template #first>
                        <b-form-select-option value="" disabled
                          >-- Option auswählen --</b-form-select-option
                        >
                      </template>
                      <option
                        v-for="(legal_form, key) in companyDetails.legal_forms"
                        :key="key"
                        :value="legal_form[0]"
                      >
                        {{ legal_form[1] }}
                      </option>
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Anzahl Angestellte"
                    v-model="companyDetails.nr_of_employees"
                    min="0"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Davon Baustellen"
                    v-model="companyDetails.employees_building_site"
                    min="0"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Davon Administrativ"
                    v-model="companyDetails.employees_administration"
                    min="0"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Anteil Frauen"
                    v-model="companyDetails.share_female"
                    min="0"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Anteil Männer"
                    v-model="companyDetails.share_male"
                    min="0"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Andere"
                    v-model="companyDetails.share_others"
                    min="0"
                  />
                </div>
              </div>

              <!--            <h4 class="mb-10 mt-10 font-weight-bold text-dark">-->
              <!--              Kennzahlen-->
              <!--            </h4>-->

              <div class="row">
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="Lehrlingsausbildung"
                    v-model="companyDetails.train_apprentices"
                    :options="checkboxOptions"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Anzahl Lehrlinge"
                    v-model="companyDetails.number_of_apprentices"
                    min="0"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="Beschäftigt Subunternehmer"
                    v-model="companyDetails.works_with_subcontractors"
                    :options="checkboxOptions"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <ValidatedInput
                    name="UDI-Nummer"
                    v-model="companyDetails.udi_number"
                    :v="$v.companyDetails.udi_number"
                    errorMessage="Das Format entspricht nicht einer gültigen UDI-Nummer."
                    help-text="Bsp: CHE-123.456.789"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="Mwst. Pflichtig"
                    v-model="companyDetails.tax_obligatory"
                    :options="checkboxOptions"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedNumberInput
                    name="Maximale Auftragsgrösse [CHF]"
                    v-model="companyDetails.max_order_size"
                    min="0"
                    :show-formatted-number="true"
                    formatted-number-prefix="CHF"
                  />
                </div>
              </div>

              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                Selbstdeklaration
              </h4>

              <div class="row">
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="Sozialleistungen einbezahlt"
                    v-model="companyDetails.social_benefits_paid_in"
                    :options="checkboxOptions"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="Betreibungen"
                    v-model="companyDetails.in_dept_enforcement"
                    :options="checkboxOptions"
                  />
                </div>
                <div class="col-xl-4">
                  <NotValidatedDropdownInput
                    name="In Konkursverfahren"
                    v-model="companyDetails.in_insolvency_proceedings"
                    :options="checkboxOptions"
                  />
                </div>
              </div>

              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                Versicherung
              </h4>

              <div class="row">
                <div class="col-xl-6">
                  <NotValidatedInput
                    name="Versicherungsgesellschaft"
                    v-model="companyDetails.assurance_society"
                    min="0"
                  />
                </div>
                <div class="col-xl-6">
                  <NotValidatedInput
                    name="Police Nr."
                    v-model="companyDetails.policy_nr"
                    min="0"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <NotValidatedNumberInput
                    name="Personenschäden Deckungsumfang [CHF]"
                    v-model="companyDetails.personal_injury_scope_of_coverage"
                    min="0"
                    :show-formatted-number="true"
                    formatted-number-prefix="CHF"
                  />
                </div>
                <div class="col-xl-6">
                  <NotValidatedNumberInput
                    name="Sachschäden Deckungsumfang [CHF]"
                    v-model="companyDetails.property_damage_scope_of_coverage"
                    min="0"
                    :show-formatted-number="true"
                    formatted-number-prefix="CHF"
                  />
                </div>
              </div>
              <!--begin: Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2"></div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
              <!--end: Actions -->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2";
import ValidatedInput from "@/components/ValidatedInput";
import NotValidatedInput from "@/components/NotValidatedInput";
import NotValidatedNumberInput from "@/components/NotValidatedNumberInput";
import NotValidatedDropdownInput from "@/components/NotValidatedDropdownInput";
import { alertMixin } from "@/mixins/alertMixin";
import { validationMixin } from "vuelidate";
import {
  LOAD_USER_COMPANY_DETAILS,
  UPDATE_USER_COMPANY_DETAILS
} from "@/store/company.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";

import { helpers } from "vuelidate/lib/validators";
const udiNumber = helpers.regex("udiNumber", /(CHE-\d{3}.\d{3}.\d{3}$){1}/);

export default {
  components: {
    ValidatedInput,
    NotValidatedInput,
    NotValidatedNumberInput,
    NotValidatedDropdownInput
  },
  mixins: [validationMixin, alertMixin],
  name: "CompanyDetails",
  data() {
    return {
      companyDetails: {},
      company: {},
      isLoaded: false,
      checkboxOptions: [
        { text: "Nein", value: false },
        { text: "Ja", value: true }
      ]
    };
  },
  validations: {
    companyDetails: {
      udi_number: {
        udiNumber
      }
    }
  },
  mounted() {
    this.loadCompany();
  },
  methods: {
    async loadCompany() {
      await Promise.all([
        this.$store.dispatch(LOAD_USER_COMPANY_DETAILS),
        this.$store.dispatch(LOAD_USER_COMPANY)
      ]).then(() => {
        this.companyDetails = this.getUserCompanyDetails;
        this.company = this.getUserCompany;
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.companyDetails.$touch();
      if (this.$v.companyDetails.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }
      // this.$v.company.$touch();
      // this.$v.selectedBkpNumber.$touch();
      // if (this.$v.company.$anyError || this.$v.selectedBkpNumber.$anyError) {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     customClass: "btn btn-secondary",
      //     heightAuto: false
      //   });
      //   return;
      // }

      this.$store
        .dispatch(UPDATE_USER_COMPANY_DETAILS, this.companyDetails)
        .then(response => {
          this.companyDetails = response.data;
          this.showChangesSavedAlert();
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    }
  },
  computed: {
    ...mapGetters(["getUserCompanyDetails", "getUserCompany"]),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 1930 },
        (value, index) => year - index
      );
    }
  }
};
</script>
