<template>
  <div class="form-group">
    <label v-if="name">{{ name }}</label>
    <b-form-select
      class="form-control form-control-lg form-control-solid"
      size="lg"
      v-model="input"
      :options="options"
    ></b-form-select>
    <!-- This is a form text block (formerly known as help block) -->
    <b-form-text :text-variant="helpTextVariant" v-if="helpText">{{
      helpText
    }}</b-form-text>
  </div>
</template>

<script>
export default {
  name: "NotValidatedRadioInput",
  props: {
    name: String,
    options: {
      required: true
    },
    value: Boolean,
    helpText: String,
    isReadOnly: {
      type: Boolean,
      default: false
    },
    helpTextVariant: {
      String,
      default: "muted"
    }
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped></style>
